.gender-select {
  position: relative;
  user-select: none;

}
.gender-select-value {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.gender-select-value-arrow {
  transform: rotate(180deg);
}
.gender-select.visible {
  .gender-select-value-arrow {
    transform: rotate(0deg);
  }
  .gender-select-hidden {
    display: block;
  }
}
.gender-select-hidden {
  width: 174px;
  position: absolute;
  display: none;
  background-color: rgba(7, 5, 19, 0.95);
  border: 1px solid var(--border-color);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 4px;
  z-index: 20;
  left: 0;
  right: 0;
  top: calc(100% + 8px);
}
.gender-select-hidden-item {
  cursor: pointer;
  width: 100%;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  display: flex;
  padding: 12px;
  transition: var(--transition);
  &:hover {
    background-color: rgba(255,255,255,0.05);
  }
  &.active {
    background-color: rgba(255,255,255,0.1);
  }
}